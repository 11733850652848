import React, { useEffect } from 'react'
import AdComponent from './AdComponent'
import {Link} from 'gatsby-plugin-react-i18next';
import { AdBlockDetectedWrapper } from "adblock-detect-react";


const RelatedMovies = ({related, customClassName, watch, taken_down}) => {

  let relatedVideos
  if (related && related.length && related.length > 0){
    relatedVideos = related.map(i => <VideoRelatedItem key={i.id} id={i.id} image={i.image} image_size={i.image_size} title={i.title} type={i.type} audio={i.audio} subtitles={i.subtitles}/>)
  } else {
    relatedVideos = []
    for (let i = 0; i < 20; i++) {
      relatedVideos.push(<VideoRelatedItemPlaceholder />)
    }
  }

  // const addUnit = Math.random() < 0.5 
  // ? <div className="mx-0 mt-0 w-80 hide-on-mobile"> <AdComponent client="ca-pub-3093835180445554" slot="2287771435" customClasses="mb-3" horizontal/> </div>
  // : <div className="mx-0 mt-0 w-100 hide-on-mobile"> <AdComponent client="ca-pub-3093835180445554" slot="1817309082" customClasses="mb-3" format="link"/>  </div>

  let showAd = false
  if (!taken_down && related && related.length && related.length > 0) {
    showAd = true
  }

  return (
    <div className={`sidebar ${customClassName}`}>
      <div className="d-flex flex-column">
        <ul className="list-unstyled mt-3">

        {/* À Suivre, Annonces textuelles et graphiques */}
        { showAd &&
          // <div className="mx-0 mt-0 w-100 hide-on-mobile"> 
          //   <AdComponent client="ca-pub-3093835180445554" slot="1817309082" customClasses="mb-3" format="link"/>  
          // </div>
          <div className="mx-0 mt-0 w-100 hide-on-mobile"> 
            <AdComponent client="ca-pub-3093835180445554" slot="2287771435" customClasses="mb-3" format="horizontal"/> 
          </div>
        }
        <AdBlockDetectedWrapper>
          <div className="mx-0 mt-0 w-100 bg-white mb-3 pb-3 pt-2">
            <p className="text-center text-secondary">Publicités</p>
            <ul className="list-group">
              <a target="_blank" rel="noopener" href="https://fr.igraal.com/?parrain=Kalsea"> 
                <li className="list-group-item text-center list-group-item-action"> 
                    Gagner de l'argent sur internet 
                </li>
              </a>
              {/* <a target="_blank" rel="noopener" href="https://www.primevideo.com"> 
                <li className="list-group-item text-center list-group-item-secondary"> 
                    Prime Video Gratuit pendant un mois
                </li>
              </a> */}
            </ul>
        </div>
        </AdBlockDetectedWrapper>
          {/* <AdComponent client="ca-pub-3093835180445554" slot="1817309082" customClasses="mb-3" format="link"/>   */}

        
        {relatedVideos}


        </ul>
      </div>
    </div>
  )
}

export default RelatedMovies

const VideoRelatedItem = props => (
  <li className="media mb-3" key={props.id} >
    <Link to={`/video/${props.id}`} state={{ videoId: props.id }} className="underline-not" title="">
      { props.image_size && props.image_size === "cover"
        ? <img loading="lazy" className="mr-3" width="168" height="95" src={props.image}/>
        : (
          <div>
            <div className="bg-placeholder mr-3" style={{width:168, height: 95, backgroundImage: `url(${props.image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat:'no-repeat', filter:"blur(2px)"}}></div>
            <div className=" mr-3 p-5 " style={{width:168, height: 95, backgroundImage: `url(${props.image})`, backgroundPosition: 'left', backgroundSize: 'contain', backgroundRepeat:'no-repeat', marginTop: "-95px", position:"absolute"}}></div>
          </div>
        )
      }
    </Link>
    <div className="media-body">
      <Link to={`/video/${props.id}`} state={{ videoId: props.id }} className="underline-not" title="">
        <h5 className="mt-0 mb-1">
          {props.title}
        </h5>
        <p>
          <span class="badge badge-dark mr-1">{props.type === 'trailer' ? 'bande annonce' : 'film' }</span>
          { props.audio != "fr" && props.subtitles === "fr"
            ? <span className="badge badge-secondary">vostfr</span> 
            : props.audio === "fr"  
                ? <span className="badge badge-secondary">{props.audio}</span> 
                : (<>
                    <span className="badge badge-secondary">{props.audio === "silent" ? "muet" : props.audio}</span> 
                    {props.subtitles != "none" && <span className="badge badge-secondary">{props.subtitles}</span> }
                    </>
                  )
          }
          
        </p>
      </Link>

    </div>
  </li>
)

const VideoRelatedItemPlaceholder = () => (
  <li className="media mb-3">
    <Link className="underline-not">
      <img className="mr-3 bg-placeholder" width="168" height="95"/>
    </Link>
    <div className="media-body">
      <Link className="underline-not">
      <div>
        <img className="bg-placeholder" width="168" height="10"/>
      </div>
      <div>
        <img className="bg-placeholder" width="168" height="10"/>
      </div>
      </Link>

    </div>
  </li>
)